<template>
  <div class="scroll-wrapper download-wrapper">
    <div class="title">多终端下载</div>
    <div class="subtitle">给您带来快捷的设计项目体验</div>
    <div class="card-wrapper">
      <div class="card-item" v-for="(item, index) in cardList" :key="index">
        <img class="card-icon" :src="`${ossPath}/assets/download/${item.icon}_icon.png`" />
        <div class="card-title">{{ item.title }}</div>
        <div class="card-subtitle">{{ item.subtitle }}</div>
        <div class="card-btn" v-if="index != 2" @click="download(item)">
          <img class="card-btn-icon" :src="`${ossPath}/assets/download/download_icon.png`" />
          <div class="card-btn-text">{{ item.btnText }}</div>
        </div>
        <img class="card-qrcode" :src="`${ossPath}/assets/download/miniprogram_code.jpg`" v-else />
      </div>
    </div>
    <div class="footer-placeholder"></div>
    <MToolBar />
  </div>
</template>

<script>
import MToolBar from "@/components/ToolBar/m_index.vue";

export default {
  name: "m_Download",
  components: { MToolBar },
  data() {
    return {
      cardList: [
        {
          icon: "apple",
          title: "MacOS",
          subtitle: "Mac12.0以上\n支持Inter芯片和M芯片",
          btnText: "Mac版",
          filename: "鹧鸪云仿真系统-1.0.0.dmg",
          downloadUrl: "https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/%E9%B9%A7%E9%B8%AA%E4%BA%91%E6%A8%A1%E6%8B%9F%E8%AE%BE%E8%AE%A1%E7%B3%BB%E7%BB%9F.dmg",
        },
        {
          icon: "windows",
          title: "Windows",
          subtitle: "windows10以上",
          btnText: "Windows  64位",
          filename: "鹧鸪云仿真系统 Setup 1.0.0.exe",
          downloadUrl:
            "https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/%E9%B9%A7%E9%B8%AA%E4%BA%91%E6%A8%A1%E6%8B%9F%E8%AE%BE%E8%AE%A1%E7%B3%BB%E7%BB%9F.exe",
        },
        {
          icon: "miniprogram",
          title: "微信小程序",
          subtitle: "微信扫一扫即可使用",
        },
      ],
    };
  },
  methods: {
    download(item) {
      let filename = item.filename;
      let a = document.createElement("a");
      a.href = item.downloadUrl;
      a.download = filename;
      a.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/m_scroll-wrapper.scss";
.download-wrapper {
  width: 100%;
  position: relative;
  background: linear-gradient(227deg, #cfdeff 0%, #e1f0f8 50%, #fcf8ed 100%);
  padding-left: 60px;
  padding-right: 60px;
  .title {
    padding-top: 183px;
    font-weight: bold;
    font-size: 68px;
    color: #222222;
    line-height: 99px;
    text-align: center;
  }
  .subtitle {
    margin-top: 50px;
    font-size: 36px;
    color: #666666;
    line-height: 52px;
    text-align: center;
  }
  .card-wrapper {
    margin-top: 110px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .card-item {
      width: 100%;
      background: #ffffff;
      width: 1005px;
      box-shadow: 0px 3px 16px 1px rgba(52, 112, 255, 0.08);
      border-radius: 30px;
      box-sizing: border-box;
      padding: 70px 0;
      &:not(:first-child) {
        margin-top: 50px;
      }
      .card-icon {
        width: 146px;
        height: 146px;
        margin: 0 auto;
        display: block;
      }
      .card-title {
        margin-top: 26px;
        font-weight: 500;
        font-size: 56px;
        color: #222222;
        text-align: center;
        line-height: 81px;
      }
      .card-subtitle {
        margin-top: 16px;
        font-size: 36px;
        color: #999999;
        text-align: center;
        line-height: 52px;
        white-space: pre-wrap;
        height: 104px;
      }
      .card-btn {
        width: 480px;
        height: 120px;
        margin: 40px auto 0;
        background: #ffffff;
        border-radius: 20px;
        border: 3px solid #3470ff;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .card-btn-icon {
          width: 48px;
          height: 48px;
          margin-right: 30px;
        }
        .card-btn-text {
          font-size: 42px;
          color: #3470ff;
        }
      }
      .card-qrcode {
        margin: 40px auto 0;
        width: 360px;
        height: 360px;
        display: block;
      }
    }
  }
}
</style>
